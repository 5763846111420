import React, { Component } from 'react';
import styled from 'styled-components';

const Item = styled.li`
  line-height: 32px;
`;

const OtherOptionInput = styled.input`
  /* !important since it collides with _mailchimp.scss */
  display: ${(props) => (props.hidden ? 'hidden' : 'inline')} !important;
  width: auto !important;
  margin: 0 0 0 8px;
  height: 32px;

  &:disabled {
    background: #ffffff;
    opacity: 60%;
  }
`;

export default class OptionSelect extends Component {
  constructor(props) {
    super(props);

    this.otherInput = React.createRef();
    this.state = {
      value: '',
      selectedOther: false,
    };
  }

  render() {
    const { name, options, required } = this.props;

    return (
      <ul>
        {options.map((option) => {
          let id = `${name}-${option}`;

          return (
            <Item key={id}>
              <input
                type="radio"
                name={name}
                id={id}
                onChange={() => this.setState({ value: option, selectedOther: false })}
                required={required}
              />
              <label htmlFor={id}>{option}</label>
            </Item>
          );
        })}
        <li>
          <input
            type="radio"
            name={name}
            id={`${name}-OTHER`}
            onChange={() => {
              this.setState({ value: '', selectedOther: true });

              setTimeout(() => {
                this.otherInput.current.focus();
              }, 100);
            }}
            required={required}
          />
          <label htmlFor={name + '-OTHER'}>Other</label>
          <OtherOptionInput
            type="text"
            className="required"
            placeholder="Tell us how..."
            disabled={!this.state.selectedOther}
            ref={this.otherInput}
            value={this.state.selectedOther ? this.state.value : ''}
            onChange={(e) => this.setState({ value: e.target.value })}
            required={required}
          />
        </li>

        <div style={{ position: 'absolute', left: '-5000px' }} aria-hidden="true">
          <input
            readOnly
            type="text"
            name={name}
            tabIndex="-1"
            value={this.state.value}
            id={'mce-' + name}
          />
        </div>
      </ul>
    );
  }
}
