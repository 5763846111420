import React from 'react';
import styled from 'styled-components';
import trackEvent from 'gatsby-plugin-simple-analytics';

import Layout from 'src/components/layout';
import MailchimpForm from 'src/components/mailchimp/form';
import { forSize } from 'src/style-utils';
import ChevronsRightIcon from 'src/assets/chevrons-right.svg';
import { H2, Text, Section, ActionButton } from 'src/components/base';

const Hero = styled.div`
  margin-bottom: 24px;
`;

const Heading = styled(H2)`
  line-height: 1.3;
`;

const JoinSection = styled(Section)`
  margin-top: 48px;

  ${forSize.phoneOnly`
    margin-top: 24px;
  `}
`;

const FormActionButton = (
  <ActionButton
    tag="button"
    text="Download Beta"
    type="submit"
    name="subscribe"
    id="mc-embedded-subscribe"
    icon={<ChevronsRightIcon />}
  />
);

export default function Join({ location: { state } }) {
  return (
    <Layout title="Join Beta" withFooter={false} withHeader={false}>
      <JoinSection>
        <Hero>
          <Heading>Just a couple of questions before we begin</Heading>
          <Text>Fill out this 30-second form to download MonoFocus beta.</Text>
        </Hero>
        <MailchimpForm
          onSubmit={() => trackEvent('send_landing_beta_form')}
          action="https://app.us18.list-manage.com/subscribe/post?u=e7187d3f582aff3ff3206f19e&id=e65f144f87"
          actionButton={FormActionButton}
          gdprFieldId="49179"
          email={(state && state.email) || ''}
        />
      </JoinSection>
    </Layout>
  );
}
