import React from 'react';
import styled from 'styled-components';
import { forSize } from 'src/style-utils';

import OptionSelect from './option-select';

const Form = styled.form`
  padding: 0 !important;
  width: 100%;

  ${forSize.laptopUp`
    width: 700px;
  `};

  ${forSize.phoneOnly`
    font-size: 16px;
  `};
`;

const Question = styled.label`
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
  margin-bottom: 16px;
`;

const FeaturedText = styled.p`
  margin: 4px 0;

  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
`;

const SmallText = styled.div`
  font-size: 12px;
  line-height: 16px;
`;

const Label = styled.p`
  margin-bottom: 16px;

  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.1px;
`;

const Input = styled.input`
  max-width: 360px;
`;

export default function MailchimpForm({
  onSubmit,
  action,
  email,
  gdprFieldId,
  hiddenFields,
  actionButton,
}) {
  return (
    <div id="mc_embed_signup">
      <Form
        onSubmit={onSubmit}
        action={action}
        method="post"
        id="mc-embedded-subscribe-form"
        name="mc-embedded-subscribe-form"
        className="validate"
        target="_blank"
      >
        <div id="mc_embed_signup_scroll">
          <div className="mc-field-group">
            <Question htmlFor="mce-EMAIL">
              Email Address <span className="asterisk">*</span>
            </Question>
            <Input
              defaultValue={email}
              type="email"
              name="EMAIL"
              className="required email"
              id="mce-EMAIL"
              placeholder="Type your email address..."
              required
            />
          </div>

          <div className="mc-field-group input-group">
            <Question>
              How did you hear about MonoFocus? <span className="asterisk">*</span>
            </Question>
            <OptionSelect
              name="ORIGIN"
              options={[
                'GitPigeon for GitHub',
                '1440.app',
                'Twitter',
                'Facebook',
                'Search engine',
                'Friend/colleague',
              ]}
              required
            />
          </div>

          <div
            id="mergeRow-gdpr"
            className="mergeRow gdpr-mergeRow content__gdprBlock mc-field-group"
          >
            <div className="content__gdpr">
              <FeaturedText>Want to have an early peek into our future projects?</FeaturedText>
              <Label>
                Select if you want to receive occasional updates, including beta access, about other
                future projects from folks behind this very app.
              </Label>
              <fieldset
                className="mc_fieldset gdprRequired mc-field-group"
                name="interestgroup_field"
              >
                <label className="checkbox subfield" htmlFor={`gdpr_${gdprFieldId}`}>
                  <input
                    type="checkbox"
                    id={`gdpr_${gdprFieldId}`}
                    name={`gdpr[${gdprFieldId}]`}
                    defaultValue="Y"
                    className="av-checkbox "
                  />
                  <span>Sure, I want to know about your other projects!</span>
                </label>
              </fieldset>
            </div>
            <SmallText className="content__gdprLegal">
              <p>
                We use Mailchimp as our marketing platform. By clicking below to subscribe, you
                acknowledge that your information will be transferred to Mailchimp for processing.{' '}
                <a href="https://mailchimp.com/legal/" target="_blank" rel="noopener noreferrer">
                  Learn more about Mailchimp's privacy practices here.
                </a>
              </p>
            </SmallText>
          </div>
          {hiddenFields &&
            hiddenFields.map((field) => (
              <div style={{ position: 'absolute', left: '-5000px' }} aria-hidden="true">
                {field}
              </div>
            ))}
          {actionButton}
        </div>
      </Form>
    </div>
  );
}
